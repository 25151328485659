/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import { Link } from "gatsby"

import Header from "./header"
import SignupFooter from './signup-footer'
import SubscribeForm from './subscribe-form'
// import "./layout.css"
import Container from "react-bootstrap/Container"
import ImgLogo from "../images/logo-vertical.svg"
import '../sass/theme.scss';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
      	<script src="https://kit.fontawesome.com/5896a96a5e.js" crossorigin="anonymous"></script>
        <meta name="google-site-verification" content="Ijg0mg1wehwNgjc0ueky6OSY9fe7eOdmmL1hd9J5T8Y" />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
        <div id="wrapper-footer">
          <SignupFooter/>

          <footer className="site-footer" id="colophon">
            <div className="site-info text-center">
              <img width="110" src={ImgLogo} />

              <SubscribeForm/>
              <p className="mt-4">
                <small>
                  &copy; 2020 Molly by{" "}
                  <a href="http://smashtaps.com" target="_blank">
                    SmashTaps
                  </a>{" "}
                  &middot; <Link to="/privacy">Privacy Policy</Link> 
                  &nbsp; <Link to="/terms">Terms of Use</Link>
                </small>
              </p>
              <p>
                <a href="https://twitter.com/ripemail" target="_blank">
                  <i className="fab fa-twitter-square"></i>
                </a>
                <a href="https://www.facebook.com/hellomolly.io/" target="_blank">
                  <i className="fab fa-facebook-square"></i>
                </a>
                <a
                  href="https://instagram.com/hellomolly.io"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </p>
            </div>
          </footer>
        </div>
      </div>
    </>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
export default Layout

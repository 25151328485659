import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, setShow } from "react"
import Container from "react-bootstrap/Container"
import Modal from "react-bootstrap/Modal"

import ImgLogo from "../images/logo.svg"
import Signup from "./signup"

function Header({ siteTitle }) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <nav className="navbar navbar-light navbar-expand-md bg-white">
        <Container>
          <a
            className="navbar-brand"
            rel="home"
            href="/"
            title="Molly"
            itemProp="url"
          >
            <img src={ImgLogo} alt="Molly Logo" height="58" />
          </a>

          <div className="header-menu">
            <a
              className="twitter-share-button text-muted d-none d-sm-inline-block"
              target="_blank"
              href="https://twitter.com/intent/tweet?text=Checkout+Molly+-+A+simple+Applicant+Tracking+System+designed+for+%23Startups.+Request+invite+for+private+beta+%23HR+%23hiring+%23recruiting+"
              data-size="large"
            >
              <i className="fab fa-twitter" aria-hidden="true"></i>
              <span className="ml-1">Tweet this</span>
            </a>

            <a
              className="ml-4 text-muted"
              type="button"
              href="javascript:;"
              onClick={handleShow}
            >
              Pricing
            </a>

            <a
              className="ml-4 text-muted d-none d-sm-inline-block"
              href="https://app.hellomolly.io/signin"
            >
              Login
            </a>
            <a
              className="button btn btn-outline-primary ml-4 d-none d-sm-inline-block"
              href="https://app.hellomolly.io/signup"
            >
              Sign Up
            </a>
          </div>
        </Container>
      </nav>
      <Modal
      className="pricing-modal"
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-5">
          <div className="text-center">
            <h3>Simple &amp; affordable pricing</h3>
            <hr/>
          <p>Sign up for our free 21 day trial and enjoy all the features.<br/>
            If you love what you see you can choose to subscribe.</p>

            {/* <div className="price-card">
              <h3>🔥 $7/month</h3>
              <ul className="list-unstyled">
                <li>Unlimited Jobs</li>
                <li>Unlimited Candidates</li>
                <li>Unlimited Users</li>
              </ul>
              <span className="small">Billed Annually</span>
            </div> */}

            <a className="button btn btn-primary btn-block-xs-only btn-lg pl-4 pr-4 mt-3" href="https://app.hellomolly.io/signup">👉 Sign up &amp; start my trial</a>

            <p className="small mb-0 mt-3">Once you sign up, we will contact you to activate your trial<br/>
            and provide pricing options tailored to your requirements.</p>

          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import SignupForm from './signup'

const Signup = ({ siteTitle }) => (
    <section className="partial__signup-footer">
	<div className="container">
		<div className="row">
			<div className="col-md-2"></div>
			<div className="col-md-8 text-center text-white">
				<h1 className="mb-4 jumbotron__heading text-white">Sign up for simple and affordable recruiting</h1>
                <SignupForm/>
			</div>
			<div className="col-md-2"></div>
		</div>
	</div>
</section>
)

export default Signup

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

class SignupForm extends React.Component {
  state = {
    EMAIL: "",
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  render() {
    return (
      <div id="mc_embed_signup">
        <p className="mb-1 mt-3">Subscribe to our newsletter</p>
        <form
          action="https://smashtaps.us11.list-manage.com/subscribe/post?u=f33e2360a713fdf68a46a5956&amp;id=6d51a4ddaf"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate form-inline"
          target="_blank"
          noValidate
        >
          <div style={{ margin:" 20px 0px 20px 50px"}}>
          <input
           
            type="email"
            value={this.state.email}
            name="EMAIL"
            className="email form-control"
            id="mce-EMAIL"
            placeholder="Enter your email"
            onChange={this.handleInputChange}
            required
            />
            </div>
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_f33e2360a713fdf68a46a5956_6d51a4ddaf"
              tabIndex="-1"
              value=""
              readOnly
            />
          </div>
          <div className="clear" style={{margin:"0 0 0 110px"}}>
            <input
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button btn btn-outline-primary btn-block-xs-only ml-md-2"
            />
          </div>
        </form>
      </div>
    )
  }
}

export default SignupForm

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

class Signup extends React.Component {
  state = {
    EMAIL: "",
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  render() {
    return (
      <div>
        <a className="button btn btn-primary btn-block-xs-only btn-lg pl-4 pr-4 mt-3" href="https://app.hellomolly.io/signup">{this.props.title || `👉  Start your free 21 day trial`}</a>
        <p className="text-muted small mt-2">No credit card required!</p>
      </div>
    )
  }
}

export default Signup
